<template>
  <BaseLayout>
    <PaymentIndex />
  </BaseLayout>
</template>

<script>
import PaymentIndex from '@/composites/transaction/payment/index/Index.vue'

export default {
  name: 'PaymentIndexPage',

  components: {
    BaseLayout: () => import('@/views/shared/BaseLayout.vue'),
    PaymentIndex,
  },
}
</script>
